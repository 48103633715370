import React, { Component } from 'react';
import axiosInstance from '../axiosApi';
import { Tooltip } from 'antd';
import { Select } from 'antd';
import { store } from '../redux/store'
import { UnconnectedEmpList } from './EmpList'
const { Option } = Select;

type Props = {
  branch_id: number,
  onChange: (key: string, value: any) => void,
};
type State = {
  selected?: any[],
  items: any[];
  branchID: number,
  loading: boolean,
};

class DeptOption extends Component<Props, State> {
  state: State = {
    selected: [],
    items: [],
    branchID: 0,
    loading: true
  };

  clearFields() {
    console.log("clearing!");
    this.setState({
      selected: undefined
    });
  }

  componentDidMount() {
    this.load();
  }

  handleChange = (e: any) => {
    console.log(e);
    this.setState({
      selected: e,
    });

    // send our selected department values back up to the parent to handle--like an adult
    this.props.onChange("dept", e);
  };

  load = () => {
    if (this.props.branch_id !== undefined) {
      try {
        // updating to new department values needs to force a blanking to the parents selection
        this.props.onChange("dept", []);

        let payload = {
          "branch_id": this.props.branch_id
        };

        this.setState({
          loading: true,
          selected: []
        });

        store.dispatch({                      // sending value true when component is called, so that the button in EmpList gets disabled
          type: "ISLOADING_Dept",
          payload:true
        });
        axiosInstance.post('shout/departments/', payload)
          .then(res => {
            const obj = res.data.departments;
            this.setState({
              items: obj,
              loading: false
            });
            store.dispatch({                      // this is sending false to store component, and enables the button along with other components
              type: "ISLOADING_Dept",
              payload:this.state.loading
            });
          });
      } catch (error) {
        console.log("Error Loading Departments: ", JSON.stringify(error, null, 4));
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.branch_id !== prevProps.branch_id) {
      this.load();
    }
  }

  render() {
    const options = this.state.items.map((d: any) => (<Option value={d.department_id} key={d.department_id}>{d.department_name}</Option>));
    return (
      <div>
        <Tooltip placement="top" title="Clearing all departments from selection will select all employees across all departments.">
          <span>
            <Select
              mode="multiple"
              placeholder="Select Departments"
              onChange={this.handleChange}
              filterOption={(input, option) => option?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              value={this.state.selected}
              loading={this.state.loading}
              style={{ width: '100%' }}
              allowClear
              disabled={this.state.loading}
            >
              {options}
            </Select>
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default DeptOption;
