// @ts-nocheck
import React, { Component, createRef, forwardRef, ReactText, RefObject } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosApi';
import Highlighter from 'react-highlight-words';

import { Button, Row, Col, Tooltip, Table, Typography, Input, Divider, message, Alert } from 'antd';
import { SettingFilled, SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { connect } from 'react-redux'
import EmpDeetsMS from './EmpDeetsOption';
import { store } from '../redux/store'
import { isTemplateSpan } from 'typescript';


const { Text, Title } = Typography;

type Props = {
  loading: boolean,
  staging: boolean,
  excluded_ids: (ids: any[]) => void,
  selected_count: (length: number) => void,
  selected_ids: any,
};
type State = {
  generate: any,
  items: any[],
  searchText: string,
  searchedColumn: string,
  selectedItems: ReactText[],
  unselectedItems: any[],
  button: boolean,
  selected_emp_deets: {
    empDeets: any[]
  },
};



class EmpList extends Component<any, State> {    //changed <Props> to <any> cuz of TypeScript

  constructor(props: any) {
    super(props)
    this.state.button = true
  }
  state: State = {
    generate: {
      loading: false
    },
    items: [],
    searchText: '',
    searchedColumn: '',
    selectedItems: [],
    unselectedItems: [],
    button: true,
    selected_emp_deets: {
      empDeets: []
    },
  };

  private empDeetsMSRef: RefObject<EmpDeetsMS> = createRef();
  private branchOptionRef: RefObject<BranchOption> = createRef();

  update() {
    if (this.props.isLoadingDept === true &&
      this.props.isLoadingEmpStatus === true &&
      this.props.isLoadingGroup === true &&
      this.props.isLoadingExGroup === true &&
      this.props.isLoadingEmpDesig === true &&
      this.state.button === false) {
      this.setState({ button: true })

    }
    if (
      this.props.isLoadingDept === false &&
      this.props.isLoadingEmpStatus === false &&
      this.props.isLoadingGroup === false &&
      this.props.isLoadingExGroup === false &&
      this.props.isLoadingEmpDesig === false &&
      this.state.button !== false) {
      this.setState({
        button: false
      })
    }
  }

  componentWillUnmount() {                   //returns null when component unmounts, so it does't hold any data. componentDidUpdate should't be used without this
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate() {
    this.update()
  } // what

// @ts-ignore
  private searchInputRef: RefObject<Input> = createRef();

  updateSelectedEmpDeets = (k: any, v: any) => {
    this.setState(prevState => ({
      ...prevState,
      selected_emp_deets: {
        ...prevState.selected_emp_deets,
        [k]: v
      }
    }));
  };

  clearFields() {
    console.log("clearing!");
    this.setState({
      items: [],
      selectedItems: [],
      unselectedItems: [],
    });
  }

  handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: any) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: (() => void) | undefined) => {
    if (!clearFilters) {
      return;
    }
    clearFilters();
    this.setState({ searchText: '' });
  };

   generateList = () => {
    // TODO: Create a log entry here for the current filter settings
    this.setState({
      items: [],
      generate: {
        loading: true
      },
      selectedItems: [],
      unselectedItems: []
    });

    // Update the parents exclude list too!
    this.props.excluded_ids(this.state.unselectedItems);

    try {
      let payload = {
        "branch_id": this.props.selected_ids.branch,
        "department_id": this.props.selected_ids.dept,
        "ac_group_ids": this.props.selected_ids.groups,
        "ac_ex_group_ids": this.props.selected_ids.ex_groups,
        "emp_statuses": this.props.selected_ids.empStatuses,
        "emp_desigs": this.props.selected_ids.empDesig,
        "ac_additional_joins": this.state.selected_emp_deets
      };

      axiosInstance.post('shout/employee/list/', payload)
        .then(res => {
          const obj = res.data.emp_list;

          // This was an attempt to select all valid values as they get loaded into the table
          // Not entirely sure why it's not working but I think it's something about how selectedItems is managed by the 

          const newSelectedItems: any[] = [];
          const newUnselectedItems: any[] = [];
          for (const [index, value] of obj.entries()) {
            if (value.employee_phone_main !== "invalid phone") {
              newSelectedItems.push(value.employee_id);
            } else {
              newUnselectedItems.push(value.employee_id);
            }
          }

          this.setState({
            selectedItems: newSelectedItems,
            unselectedItems: newUnselectedItems,
            items: obj,
            generate: { loading: false },
          }, () => {
            this.props.selected_count(newSelectedItems.length);
            this.props.excluded_ids(newUnselectedItems);
            this.loadSuccces();
          });
        });
    } catch (error) {
      console.log("Error Loading Departments: ", JSON.stringify(error, null, 4));
    }
  };

  clearTable = () => { // on branch change; 
    // NOT for clearing selection; this is for clearing EMPLOYEE LIST
    // ON BRANCH CHANGE (different component); in effect, the rows
    // should disappear, because that's the default state ON LOAD.
    // Generate SMS List button takes BRANCH into account, and
    // we must remove the table rows ON BRANCH CHANGE. (as per task spec)
    // EASIER SAID THAN DONE.
    // Table DOM does not update just because we do
    EmpListTable = document.getElementById("theemplist");
    EmpListTable.datasource = []
    this.setState({items: []})
    EmpListTable.datasource = this.state.items;
    // nor is it designed to.
  }

  handleDetailsSwitch = (e: any) => {

    console.log(e)
  }

  getColumnSearchProps = (dataIndex: string, indexName: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={this.searchInputRef}
          placeholder={`Search ${indexName}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: { [x: string]: any; }) => {
      let temp = record[dataIndex];
      if (temp === null) { temp = ""; }
      return temp
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInputRef?.current?.select());
      }
    },
    render: (text: { toString: () => string; }) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleOverrideSelectAll = (selected: any, selectedRows: any, changeRows: any[]) => {

      const newSelectedItems = Array();
      const newUnselectedItems = Array();

      this.state.items.forEach((value: any) => {
        if (value.employee_phone_main !== "invalid phone") {
          newSelectedItems.push(value.employee_id);
        } else {
          newUnselectedItems.push(value.employee_id);
        }
      });

      this.setState({ 
        unselectedItems: newUnselectedItems,
        selectedItems: newSelectedItems 
    },
        () => {
          this.props.excluded_ids(this.state.unselectedItems);
          this.props.selected_count(this.state.selectedItems.length);
        });

      
    
  };

  handleOverrideSelectNone = (selecteselected: any, selectedRows: any, changeRows: any[]) => {
    const newUnselectedItems = Array();

    this.state.items.forEach((value: any) => {
        newUnselectedItems.push(value.employee_id);
    });

    this.setState({ 
      unselectedItems: newUnselectedItems,
      selectedItems: Array() 
  },
      () => {
        this.props.excluded_ids(this.state.unselectedItems);
        this.props.selected_count(this.state.selectedItems.length);
      }
    );

    
  };

  handleChange = (selectedRowKeys: ReactText[]) => {
    this.setState({ selectedItems: selectedRowKeys });
    this.props.selected_count(selectedRowKeys.length);
  };

  handleSelect = (record: any, selected: any) => {
    // if selected is true, we're adding to the "include list" and need to remove it from the exclude list
    // if selected is false, we're deleting from the include list and need to add it to the exclude list
    if (selected) {
      const newArray = [...this.state.unselectedItems];
      const index = newArray.indexOf(record.employee_id);
      if (index !== -1) {
        newArray.splice(index, 1);
        this.setState({ unselectedItems: newArray },
          () => this.props.excluded_ids(this.state.unselectedItems));
      }
    } else {
      // add to the unselectedItems list
      this.setState({ unselectedItems: [...this.state.unselectedItems, record.employee_id] },
        () => this.props.excluded_ids(this.state.unselectedItems));
    }
  };

  handleSelectAll = (selected: any, selectedRows: any, changeRows: any[]) => {
    if (selected) {
      // adding to the exclude list
      // for each entry that is being selected, we need to remove it from the excluded id list
      // get the current list form state casuse state is slow

      // IF EXIST SELECTED ITEMS
      //
      // 1. init new array from unselected items
      // 2. for each in changeRows(?)
      // 2.1 get index of row record employee id
      // 2.2 if (index is valid)
      // 2.2.1 remove element from new array (will reorder array)
      // 3. set unselected items to new array
      // 3.1 with side effect of setting excluded_ids to unselected items
      //
      // ELSE
      //
      // 1. init new array and set it to unselected items
      // 2. for each in changeRows(?)
      // 2.1 add row record employee id to new array
      // 3. set unselected items to new array
      // 3.1 with side effect of setting excluded_ids to unselected items

      //find the entries, one by one, and remove them iterative loop
      const newArray = [...this.state.unselectedItems];
      changeRows.forEach(recRemove => {
        const index = newArray.indexOf(recRemove.employee_id);
        if (index !== -1) {
          newArray.splice(index, 1);
        }
      });
      this.setState({ unselectedItems: newArray },
        () => this.props.excluded_ids(this.state.unselectedItems));
    } else {
      // for each entry that gets unselected, we need to add it to the excluded list
      const newArray = this.state.unselectedItems;
      changeRows.forEach((record) => newArray.push(record.employee_id));
      this.setState({ unselectedItems: newArray },
        () => this.props.excluded_ids(this.state.unselectedItems));
    }
  };

  loadSuccces = () => {
    message.success("Found " + this.state.items.length + " people.");
  };

  polyColumnSorter = (a: any, b: any) => {


  };

   render() {
    const employeesmscolumns = [
      {
        title: 'First Name',
        dataIndex: 'employee_first_name',
        ...this.getColumnSearchProps('employee_first_name', 'First Name'),
        sorter: (a: any, b: any) => a.employee_first_name.toUpperCase() < b.employee_first_name.toUpperCase() ? -1 : 1
      },
      {
        title: 'Last Name',
        dataIndex: 'employee_last_name',
        ...this.getColumnSearchProps('employee_last_name', 'Last Name'),
        sorter: (a: any, b: any) => a.employee_last_name.toUpperCase() < b.employee_last_name.toUpperCase() ? -1 : 1
      },
      {
        title: 'AC ID',
        dataIndex: 'employee_ac_id',
        ...this.getColumnSearchProps('employee_ac_id', 'AC ID'),
        render: (text: any, record: any, c: any) => <a href={record.employee_link} target="_blank">{record.employee_ac_id}</a>,
        sorter: (a: any, b: any) => a.employee_ac_id.toUpperCase() < b.employee_ac_id.toUpperCase() ? -1 : 1
      },
      {
        title: 'Job Title',
        dataIndex: 'employee_job_title',
        ...this.getColumnSearchProps('employee_job_title', 'Job Title'),
        sorter: (a: any, b: any) => a.employee_job_title.toUpperCase() < b.employee_job_title.toUpperCase() ? -1 : 1
      },
      {
        title: 'Phone Number',
        dataIndex: 'employee_phone_main',
        ...this.getColumnSearchProps('employee_phone_main', 'Phone Number'),
        sorter: (a: any, b: any) => a.employee_phone_main.toUpperCase() < b.employee_phone_main.toUpperCase() ? -1 : 1
      },
      //this.state.selected_emp_deets ?
      //  {
      //    title: 'City',
      //    dataIndex: 'employee_city',
      //    ...this.getColumnSearchProps('employee_city', 'City'),
      //  } : {},
      //this.state.selected_emp_deets ? {
      //  title: 'State',
      //  dataIndex: 'employee_state',
      //  ...this.getColumnSearchProps('employee_state', 'State'),
      //} : {},
    ];

    // These two are managing the row selection but I can't pass a default checked in to the CheckboxProps function
    const rowSelection = {
      selectedRowKeys: this.state.selectedItems,
      onChange: this.handleChange,
      onSelect: this.handleSelect,
      onSelectAll: this.handleSelectAll,
      getCheckboxProps: (record: any) => ({
        disabled: record.employee_phone_main === 'invalid phone', // Column configuration not to be checked
      }),
    };
// @ts-ignore
    return (
      <div>
        {/* Commenting this out till we fix the speed issues
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <Title level={4}>Fetch additional employee data</Title>
            <EmpDeetsMS ref={this.empDeetsMSRef} onChange={this.updateSelectedEmpDeets} />
          </Col>
        
        </Row>
        <Divider />
        */}
        <Row gutter={[32, 16]}>
          <Col span={6}>
            <Tooltip placement="right" title="Click here to generate list of phone list of employees for SMS notification sending."  >
              <Button
                className="generateshoutlists"
                type="primary"
                icon={<SettingFilled />}
                loading={this.state.button}
                onClick={this.generateList}
                disabled={this.state.button} // this should be changed with Redux
              >
                Generate SMS List
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Divider />
                
        <Table
        id="theemplist"
          columns={employeesmscolumns}
          dataSource={this.state.items}
          rowKey={record => record.employee_id}
          rowSelection={rowSelection}
          pagination={
// @ts-ignore 
            {
// @ts-ignore 
            position: 'bottom'
            
          }}
          
          sortDirections={['ascend', 'descend']}
          loading={this.state.generate.loading}
          size='middle'
          title={
            () => 
            <div>
            <Title level={4}>Employee SMS Notification List</Title>
            <Divider />
            <Text >Selection override&nbsp;&nbsp;</Text>
            <Tooltip 
              placement="top" 
              title="Selects every employee on every page."  >
              <Button
                className="selectoverride"
                type="default"
                onClick={this.handleOverrideSelectAll}
                // disabled={this.state.button} // this should be changed with Redux
              >
                ALL
              </Button>
            </Tooltip>
            <Tooltip 
              placement="top" 
              title="Clears selected employees on every page."  >
              <Button
                className="selectoverride"
                type="default"
                onClick={this.handleOverrideSelectNone}
                // disabled={this.state.button} // this should be changed with Redux
              >
                NONE
              </Button>
            </Tooltip>
            
            </div>
          }
        />
      </div>
    );
  }
}



function mapStateToProps(state: any) {
  //built in function that should map store states into Component Props
  return {
    isLoadingDept: state.isLoading.isLoadingDept,
    isLoadingEmpDesig: state.isLoading.isLoadingEmpDesig,
    isLoadingEmpStatus: state.isLoading.isLoadingEmpStatus,
    isLoadingGroup: state.isLoading.isLoadingGroup,
    isLoadingExGroup: state.isLoading.isLoadingExGroup,


  }
}

export { EmpList as UnconnectedEmpList };   //this is used in ShoutTool.tsx cuz default is wrapped in connect() function that is also Redux 
export default connect(mapStateToProps)(EmpList)
