import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, Button, Typography, Alert } from "antd";
import { store } from "../redux/store";

const { Title } = Typography;
const { Text } = Typography;

type Props = {
  location: any;
  history: any;
  loggedIn: () => void;
};
type State = {
  show_error: boolean;
  error_message?: string;
  redirectToReferrer: boolean;
  isAuthenticated: boolean;
  is_loading: boolean;
  username?: string;
  password?: string;
};

class Login extends Component<Props, State> {
  state: State = {
    show_error: false,
    error_message: undefined,
    redirectToReferrer: false,
    isAuthenticated: false,
    is_loading: false,
  };

  componentDidMount() {
    const pre_u = process.env.REACT_APP_PRELOAD_USER
    const pre_p = process.env.REACT_APP_PRELOAD_PW
    if (pre_u !== undefined && pre_p !== undefined) {
      console.log("Auto login detected.")
      this.handleSubmit({ username: pre_u, password: pre_p })
    }
  }

  onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  handleSubmit = (event: any) => {
    this.setState({
      show_error: false,
      error_message: undefined,
      is_loading: true,
    });

    try {
      axiosInstance
        .post("/api/token/obtain/", {
          username: event.username,
          password: event.password,
        })
        .then((response) => {
          axiosInstance.defaults.headers["Authorization"] =
            "JWT " + response.data.access;
          localStorage.setItem("access_token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);
          sessionStorage.setItem("isAuth", 'true')
          this.setState(
            {
              redirectToReferrer: true,
              isAuthenticated: true,
            },
            () => this.props.loggedIn()
          );

        })
        .catch((error) => {
          console.log("login error");
          this.setState({
            error_message: error.response.data.detail,
            show_error: true,
            is_loading: false,
          });
        });
    } catch (error) {
      throw error;
    }
  };

  handleFailure = (event: any) => {
    console.log("FAILED Received values of form: ", event);
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      this.props.history.push(from.pathname);
    }

    return this.state.isAuthenticated ? (
      <Redirect to="/" />
    ) : (
      <div>
        <Row>
          <Col span={8} offset={8}>
            <Title level={3}>Welcome to Rubi Space!</Title>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={this.handleSubmit}
              onFinishFailed={this.handleFailure}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                  value={this.state.username}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                />
              </Form.Item>
              {this.state.show_error ? (
                <Form.Item>
                  <Alert
                    message={this.state.error_message}
                    type="error"
                    showIcon
                    closable
                  />
                </Form.Item>
              ) : null}
              <Form.Item>
                <Button
                  type="primary" htmlType="submit"
                  className="login-form-button"
                  loading={this.state.is_loading}
                  icon={<LoginOutlined />}>
                  Log in
                </Button>
              </Form.Item>
            </Form>
            <Text>Please submit <a href={process.env.REACT_APP_SUPPORT} target="_blank" rel="noopener noreferrer">support ticket</a> to have password reset.</Text>
          </Col>
        </Row>
      </div >
    )
  }
}
export default Login;
