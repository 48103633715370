import React, { Component, useEffect, useState } from 'react';
import axiosInstance from '../axiosApi';
import { Timeline, Skeleton, Table, message, Button, InputNumber, Pagination, Modal } from 'antd';
import { connect } from 'react-redux';
import { PageSelector } from '../features/ServerSidePaginator/Ssp';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

import {
    paginationPrev,
    paginationNext,
    paginationExact,
    pageGoto,
    populateItems,
} from '../features/ServerSidePaginator/sspSlice';

const modalContext = React.createContext({});
const modalContent = {
    title: 'Recepient phone numbers',
    content: (
        <>
            <modalContext.Consumer>{phone_list => `${phone_list}`}</modalContext.Consumer>
        </>
    ),
}

export default function ShoutHistory(props: any) {
    const urlPrev = useAppSelector((state) => state.ssp.urlPrev);
    const urlNext = useAppSelector((state) => state.ssp.urlNext);
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const dispatch = useAppDispatch();
    // const [selectedPage, setSelectedPage] = useState('2');
    const [pageNumber, setPageNumber] = useState(useAppSelector((state) => state.ssp.indexCurrent || 1));
    const [pageSize, setPageSize] = useState(useAppSelector((state) => state.ssp.pageSize));
    const [isModalVisible, setModalVisible] = useState(false);
    const expandable = {
        expandedRowRender: (record: any) => record.d_j.phone_list, //.forEach((i:string) => {{<p>PHONE {i}</p>}}),
        rowExpandable: (record: any) => record.d_j.phone_list instanceof Array,
        defaultExpandAllRows: false,
    };

    const [modal, contextHolder] = Modal.useModal();

    const columns = [
        {
            title: 'Time Stamp',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Message',
            dataIndex: 'body',
            key: 'body',
        },
        {
            title: 'Sender',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch',
        }
    ];

    useEffect(() => {
        load("shout/history/", "?page=1");
        setPageSize(50);

    }, []);

    const load = (urlPath: string, queryArgs: string) => {
        try { // does NOT effect promises (.then(s))
            axiosInstance.get(urlPath + queryArgs)
                .then(res => {
                    try {
                        dispatch(populateItems(res.data));
                        setLoading(false);
                    } catch (e) {
                        console.log(
                            res.statusText + "; " +
                            res.config.url + "-> " +
                            (res.data.length ? "(some data)" : "no data") + "\n" +
                            e
                        );
                        setLoading(false);
                        loadFail();
                    }
                });
        } catch (error) {
            console.log("Error Loading Histories: ", JSON.stringify(error, null, 4));
        }
    };

    const loadFail = () => {
        message.warning("Loading data failed.");
    };

    const showModal = (e: any) => {

        setModalVisible(true);
    };

    const onModalOK = () => {
        setModalVisible(false);
    };

    return (



        <modalContext.Provider value={["don't know"]}>
            <div>
                <div>
                    <Table
                        columns={columns}
                        pagination={false}
                        rowKey={(record: any) => record.d_j.key}

                        expandable={{
                            expandedRowRender: (record: any) => <p>{record.d_j.phone_list}</p>, //.forEach((i:string) => {{<p>PHONE {i}</p>}}),
                            rowExpandable: (record: any) => record.d_j.phone_list instanceof Array && record.d_j.phone_list != [],
                        }}
                        dataSource={useAppSelector((state) => state.ssp.items)}
                    />



                    <Pagination
                        defaultCurrent={1}
                        // defaultPageSize={1}
                        current={pageNumber}
                        total={useAppSelector((state) => state.ssp.itemCount)}
                        pageSize={useAppSelector((state) => state.ssp.pageSize)}
                        onChange={(page: number, pageSize?: number | undefined) => {
                            setPageNumber(page);
                            load("shout/history/", "?page=" + page);
                        }} />

                    {contextHolder}

                </div>
            </div>
        </modalContext.Provider>

    )
}
