import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store'
import {Provider} from 'react-redux'
import {createStore} from 'redux'

render(
  //<React.StrictMode>                 had to turn this off because was getting strict error when Redux was implemented
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);
