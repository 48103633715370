
const initialState = {
       auth: false
};

const isAuthReducer = (state = initialState, action: any) => {
       switch (action.type) {
              case "Auth":
                     return {
                            auth: action.payload
                     }
              default:
                     return state;
       }

}



export default isAuthReducer
