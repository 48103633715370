import { bindActionCreators, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { string } from 'prop-types';
import { RootState, AppThunk } from '../../redux/store';
import { fetchCount } from './sspAPI';

export interface PageState {
  indexCurrent: number;
  urlCurrent: string;
  urlNext: string;
  urlPrev: string;
  pageSize: number;
  pageGoto: number;
  itemCount: number;
  items: Array<object>;
  status: 'idle' | 'loading' | 'failed';
}

export interface drfPaginationAPI {
  count: number;
  next: string;
  previous: string;
  results: Array<Object>;
  itemsPerPage: number; // TODO get this from backend API
}

const initialState: PageState = {
  indexCurrent: 1,
  urlCurrent: "",
  urlNext: "",
  urlPrev: "",
  pageSize: 50,
  pageGoto: 0,
  itemCount: 0,
  items: [],
  status: 'idle',
};

function time_tzabbrev(in_date: Date) {
  return in_date.toLocaleString(
    'en-US', { // abbreviate timezone (must do manually)
      timeZoneName: 'long' 
    }).split(
      ' '
    ).slice(3).map(
      el => el[0]
    ).join(
      ''
    ).toUpperCase();
};

function time_nextrun(in_date: Date) {
  return in_date.toLocaleString('en-US', {
    year: 'numeric', 
    month: 'numeric', 
    day: 'numeric', 
    hour:'2-digit', 
    hour12: true, 
    minute: '2-digit',
  }) + " " + time_tzabbrev(in_date);

};

function time_localize(in_date: Date) {
  const now = new Date(Date());
  const tz_offset = now.getTimezoneOffset() / 60;
  const next_date = in_date;

  console.log(
    "Now: " + now + "\n" +
    "Offset: " + tz_offset + "\n" + 
    "t(Next): " + next_date + "\n"
  );

  next_date.setHours( // Resets TZ
    next_date.getHours() //+ -tz_offset
  );

  console.log(
    "t(NextAdj): " + next_date + "\n"      
  );

  return next_date;

};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const pageSlice = createSlice({
  name: 'counter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    paginationNext: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.indexCurrent += 1; // <-- PAGE NEXT      
    },
    paginationPrev: (state) => {
      state.indexCurrent -= 1; // <-- PAGE PREV
    },
    populateItems: (state, action: PayloadAction<drfPaginationAPI>) => {
      const obj = action.payload.results;

      obj.forEach((o: any, i:number) => {
        let d_j = JSON.parse(o.data);
        //2021-11-03T16:09:34.248820Z
        o.created = time_nextrun( // MISNOMER, just formatting...
          time_localize(new Date(o.created))).toString(); //Date.parse(v.timestamp);
        d_j.key = i;
        o.body = d_j.message_body ? d_j.message_body : "";
        o.url = d_j.url ? d_j.url : "";
        o.s_id = o.url ? o.url.split("/") : "";
        o.s_id = o.s_id ? o.s_id[6] : "";
        o.d_j = d_j;
        o.lookup_url = 'https://console.twilio.com/us1/service/notify/' + o.s_id + '/notify-service-logs?frameUrl=' + encodeURIComponent(o.url.replace(/https?:\/\/[^\/]+/i, ""));
      })
      state.urlNext = action.payload.next ? action.payload.next : "";
      state.urlPrev = action.payload.previous ? action.payload.previous : "";
      state.itemCount = action.payload.count ? action.payload.count : 0;
      state.items = action.payload.results; // hey
      state.pageSize = action.payload.itemsPerPage || 50;
      console.log("ITEM COUNT " + state.itemCount);
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    paginationExact: (state, action: PayloadAction<number>) => {
      state.indexCurrent = action.payload; // <-- PAGE GOTO
    },
  },



  // B E L O W  A R E  E X A M P L E S

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      });
  },
});



// NOTE ACTION CREATORS
export const { paginationNext, paginationPrev, paginationExact, populateItems } = pageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const pageGoto = (state: RootState) => state.ssp.pageGoto;


// NOTE MAYBE WE SHOULD USE THIS FIXME TODO 
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount: number): AppThunk => (
  dispatch,
  getState
) => {
  const currentValue = pageGoto(getState());
  if (currentValue % 2 === 1) {
    dispatch(paginationExact(amount));
  }
};


export default pageSlice.reducer;
