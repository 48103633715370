import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { store } from '../redux/store'

type Props = {
  path: string[],
  isAuthenticated: boolean,
  children: any,
  render?: (props: RouteComponentProps<any>) => React.ReactNode,
  exact?: boolean,
};

const PrivateRoute = ({ render, exact, path, isAuthenticated , children }: Props) => {

  if(sessionStorage.getItem("isAuth") === 'true')
    isAuthenticated = true
  else isAuthenticated = false

  return (
    <Route render={render} exact={exact} path={path}>
      {isAuthenticated ?
        (children) :
        <Redirect to="/login/" />}
    </Route>
  );
};

export default PrivateRoute;
