import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector; // <-- function alias


// NOTE react-redux has hooks
// for TS
// TS types say how those hooks work
// but they do not apply to generics

//  import 3 hooks
//  
//  define pretyped app versions of dispatch and selector
//      useAppDispatch => T<AppDispatch>
//      useAppSelector => T<RootState>