import { useEffect, useState } from 'react';
import { Table, message, Pagination } from 'antd';
import axiosInstance from '../../axiosApi';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
    sparklesPopulateItems
} from '../../features/ServerSidePaginator/sspSliceSparkles';
import axios from 'axios';
import time_localize from './Sparkles';

export default function SparklesHistory(props: any) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(useAppSelector((state) => state.sspSparkles.indexCurrent || 1));
    const [pageSize, setPageSize] = useState(useAppSelector((state) => state.sspSparkles.pageSize));
    const columns = [
        {
            title: 'Time Stamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
        },
        {
            title: 'Recipient',
            dataIndex: 'recipient',
            key: 'recipient',
        },
        {
            title: 'Message',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: 'Sending Result',
            dataIndex: 'response',
            key: 'response',
        },
        {
            title: 'Branch',
            dataIndex: ['sparkle_run', 'sparkle', 'branch_owner', 'name'],
            key: 'branch_owner',
        }
    ];

    useEffect(() => {
        load("sparkles/history/", "?page=1");
        setPageSize(50);

    }, []);

    const load = (urlPath: string, queryArgs: string) => {
        axiosInstance.get(urlPath + queryArgs)
            .then(res => {
                try {
                    dispatch(sparklesPopulateItems(res.data));
                    setLoading(false);
                } catch (e) {
                    console.log("Error fetching Sparkles history → " + e);
                    setLoading(false);
                }
            });
    };


    return (
        <div>
            <div>
                {
                    <Table dataSource={useAppSelector((state) => state.sspSparkles.items)}
                        columns={columns}
                        pagination={false} />

                }

                <Pagination
                    defaultCurrent={1}
                    // defaultPageSize={1}
                    current={pageNumber}
                    total={useAppSelector((state) => state.sspSparkles.itemCount)}
                    pageSize={useAppSelector((state) => state.sspSparkles.pageSize)}
                    onChange={(page: number, pageSize?: number | undefined) => {
                        setPageNumber(page);
                        load("sparkles/history/", "?page=" + page);
                    }} />
            </div>
        </div >

    )

};
