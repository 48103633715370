import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import Login from "./components/Login";
import Shout from "./components/Shout";
import Sparkles from "./components/sparkles/Sparkles";
import Account from "./components/Account";
import PrivateRoute from "./components/PrivateRoute";
import jwtDecode, { JwtPayload } from "jwt-decode";
import axiosInstance, { refreshToken } from "./axiosApi";
import { store } from "./redux/store";
import "./App.css";
import { Layout, Menu, Button } from "antd";
import { Row, Col } from "antd";

import {
  TeamOutlined,
  UnorderedListOutlined,
  SoundOutlined,
  LoginOutlined,
  LogoutOutlined,
  ClockCircleOutlined,
  RocketOutlined,
  AlertOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import Item from 'antd/lib/list/Item';

const { Header, Content, Footer, Sider } = Layout;

type Props = {};
type State = {
  isAuthenticated: boolean;
  selectedMenuItem: string;
  defaultSelectedMenuItem: string;
  countryCode?: string;
};

// the deskcontactwidget is an explicit assignment
// to a global object; various TypeScript specs
// prevent this, so we need to
declare global {
  interface Window {
    deskcontactwidget: any;
  }
}

var installHelpDeskWidget = function () {
  // helpdesk widget
  var basehref = "https://portal.rubi.works",
    token = "094b9f36-1c7e-4d8b-a398-0af2ed536551";

  window.deskcontactwidget = {};
  var r = document.getElementsByTagName("script")[0],
    c = document.createElement("script");
  c.type = "text/javascript";
  c.async = !0;
  c.src = basehref + "/support/v1/contact/main.js?token=" + token;

  if (r.parentNode)
    r.parentNode.insertBefore(c, r);

  window.addEventListener("message",
    function (e) {
      var t = e.data[0], a = e.data[1];
      var deskcontactwidget = document.getElementById("deskcontactwidgetframe");

      switch (t) {
        case "setContactFormHeight":
          if (deskcontactwidget instanceof HTMLElement)
            deskcontactwidget.style.height = Math.min(
              a, window.window.innerHeight - 75).toString() + "px";
          break;
        default:
          break;
      }
    }, !1);

};
class App extends Component<any, State> {
  state: State = {
    isAuthenticated: false,
    selectedMenuItem: "shout", // bc its at "/"
    defaultSelectedMenuItem: "shout",
    countryCode: process.env.REACT_APP_COUNTRY_CODE,
  };

  handleLogout = async () => {
    try {
      sessionStorage.setItem("isAuth", 'false')                                        //store.dispatch({ type: "Auth", payload: false });
      this.setState({ isAuthenticated: false });
      const response = await axiosInstance.post("api/blacklist/", {
        refresh_token: localStorage.getItem("refresh_token"),
      });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers["Authorization"] = null;
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    this.loginStatus();
    console.log(process.env.REACT_APP_COUNTRY_CODE);
    installHelpDeskWidget();
  }


  loginStatus = () => {
    const token: string | null = localStorage.getItem("access_token");
    if (token !== null) {
      const jwtDecoded: JwtPayload = jwtDecode(token);
      let tokenExpiration: any = jwtDecoded.exp;
      let dateNow = new Date();
      // check on the token and refresh if it's expired
      if (token && tokenExpiration < dateNow.getTime() / 5000) {

        try {
          sessionStorage.setItem("isAuth", 'true');
          refreshToken(localStorage.getItem("refresh_token"))
            .then(() => {
              this.setState({
                isAuthenticated: true,
                // selectedMenuItem: "logout",
              });
            })
            .catch((err: Error) => {
              sessionStorage.setItem("isAuth", 'false');
              console.log(err);
              this.setState({
                isAuthenticated: false,
                selectedMenuItem: "login",
              });
            });
        } catch (e) {
          sessionStorage.setItem("isAuth", 'false');
          console.log("Error passively refreshing token: " + e);
          this.setState({
            isAuthenticated: false,
            selectedMenuItem: "login",
          });
        }
      } else if (token && sessionStorage.getItem("isAuth") === 'true') {
        this.setState({
          isAuthenticated: true,
          //selectedMenuItem: "logout",
        });
      }
    } else {
      sessionStorage.setItem("isAuth", 'false');
      this.setState({
        isAuthenticated: false,                    //maybe true
        selectedMenuItem: "login",
      }); //isAuthenticated: store.getState().isAuth.auth,
    }
  };

  onMenuSelect = (e: any) => {
    this.setState({
      selectedMenuItem: e.key
    })
    this.loginStatus();
    console.log(e);
  };

  render() {
    return (
      <div className="site">
        <Layout>
          <Sider breakpoint="lg" collapsedWidth="0">
            <div className="logo" />
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[this.state.selectedMenuItem]}
              defaultSelectedKeys={[this.state.defaultSelectedMenuItem]}
              onSelect={this.onMenuSelect}
            >
              <Menu.ItemGroup key="g1" title="Modules">
                <Menu.Item key="shout">
                  <Link className={"nav-link"} to={"/shout/"}>
                    <SoundOutlined />
                    Shout
                  </Link>
                </Menu.Item>
                <Menu.Item key="sparkles" className={"sparkles"}>
                  <Link className={"nav-link"} to={"/sparkles/"}>
                    <ClockCircleOutlined />
                    Sparkles / Launch
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>

              {this.state.isAuthenticated ? (
                <Menu.ItemGroup key="accountGroup" title="Account">
                  <Menu.Item key="account">
                    <Link className={"nav-text nav-link"} to={"/account/"}>
                      <LockOutlined />
                      Security
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="logout">
                    <Link
                      className={"nav-text nav-link"}
                      onClick={this.handleLogout}
                      to={"/login/"}
                    >
                      <LogoutOutlined />
                      Logout
                    </Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              ) : (
                <Menu.Item key="login">
                  <Link className={"nav-text nav-link"} to={"/login/"}>
                    <LoginOutlined />
                    Login
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          <Layout>
            <Header
              className="site-layout-sub-header-background"
              style={{ padding: 0 }}
            >
              <Row justify="end" gutter={[16, 16]}>
                <Col flex={3} span={3}>
                  <Button
                    icon={<TeamOutlined />}
                    type="primary"
                    target="_blank"
                    href={process.env.REACT_APP_SUPPORT}
                  >
                    Support
                  </Button>
                </Col>
                <Col flex={3} span={3}>
                  <Button
                    icon={<UnorderedListOutlined />}
                    target="_blank"
                    href={process.env.REACT_APP_HOWTO}
                  >
                    How To
                  </Button>
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: "24px 16px 0" }}>
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                <Row>
                  <Col span={24}>
                    <Switch>
                      <Route
                        exact
                        path={"/login/"}
                        render={(props) => (
                          <Login {...props} loggedIn={this.loginStatus} />
                        )}
                      />
                      <PrivateRoute
                        exact
                        path={["/shout/", "/"]}
                        isAuthenticated={this.state.isAuthenticated}
                      >
                        <Shout />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={["/sparkles/"]}
                        isAuthenticated={this.state.isAuthenticated}
                      >
                        <Sparkles />
                      </PrivateRoute>
                      <PrivateRoute
                        exact
                        path={["/account/"]}
                        isAuthenticated={this.state.isAuthenticated}
                      >
                        <Account />
                      </PrivateRoute>
                    </Switch>
                  </Col>
                </Row>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              <span>
                Rubi Space ©2020 Created by Rubi.Works<br></br>v2021.10.14
              </span>
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    isAuth: state.isAuth.auth,
  };
}

export default connect(mapStateToProps)(App);
