import React, { Component, createRef, RefObject } from 'react';

import BranchOption from './BranchOption';
import DeptOption from './DeptOption';
import GroupOption from './GroupOption';
import ExGroupOption from './ExGroupOption';
import EmpStatusOption from './EmpStatusOption';
import EmpDesigOption from './EmpDesigOption';
import EmpList, { UnconnectedEmpList } from './EmpList';       //changed EmpList to UnconnectedEmpList/ see EmpList for reference
import TwilioAccount from './TwilioAccount';
import SendButton from './SendButton';

import { Row, Col, Tooltip, Form, Typography, Divider } from 'antd';


const { Title, Text } = Typography;

type Props = {
  handleSend: (url: string, msg: string) => void,
};
type State = {
  selected: {
    branch?: any,
    dept: any[],
    groups: any[],
    ex_groups: any[],
    empStatuses: any[],
    empDesig?: any,
  },
  excluded_ids: any[],
  branches_loading: boolean,
  message_char_count: number,
  selected_count: number,
  staging: boolean,
  character_limit: number,
};

class ShoutTool extends Component<Props, State> {
  state: State = {
    selected: {
      branch: undefined,
      dept: [],
      empStatuses: [],
      groups: [],
      ex_groups: [],
      empDesig: undefined,
    },
    excluded_ids: [],
    branches_loading: true,
    message_char_count: 0,
    selected_count: 0,
    staging: false,
    character_limit: 160
  };

  private sendButtonRef: RefObject<SendButton> = createRef();
  private empListRef: RefObject<UnconnectedEmpList> = createRef();     //changed EmpList to UnconnectedEmpList/ see EmpList for reference
  private deptOptionRef: RefObject<DeptOption> = createRef();
  private groupsOptionRef: RefObject<GroupOption> = createRef();
  private exGroupsOptionRef: RefObject<ExGroupOption> = createRef();
  private empStatOptionRef: RefObject<EmpStatusOption> = createRef();

  componentDidMount() {
    this.load();
  }

  updateSelected = (k: any, v: any) => {
    this.setState(prevState => ({
      ...prevState,
      selected: {
        ...prevState.selected,
        [k]: v
      }
    }));
  };

  updateExcluded = (e: any) => {
    console.log("current ex list", this.state.excluded_ids);
    console.log("new ex list", e);
    this.setState({ excluded_ids: e });
  };

  updateLoading = (loadingState: boolean) => {
    this.setState({ branches_loading: loadingState });
  };

  load = () => { 
    
    console.log("Shout loaded");
  };

  handleStaging = (e: boolean) => {
    this.setState({
      staging: e
    });
  };

  updateSMSCharCount = (e: number) => {
    this.setState({
      message_char_count: e
    });
  };

  updateSelectedCount = (e: any) => {
    this.setState({ selected_count: e });
  };

  updateCharacterLimit = (e: any) => {
    this.setState({ character_limit: e });
  };

  handleSend = (url: any, msg: any) => {
    this.props.handleSend(url, msg);
  };

  clearFields() {
    // call each of the components in the page to clear their selections
    this.setState({
      message_char_count: 0,
      selected_count: 0,
    });

    this.sendButtonRef?.current?.clearFields();
    this.empListRef?.current?.clearFields();
    this.deptOptionRef?.current?.clearFields();
    this.groupsOptionRef?.current?.clearFields();
    this.exGroupsOptionRef?.current?.clearFields();
    this.empStatOptionRef?.current?.clearFields();
  }

  render() {
    return (
      <div>
        <Form
          size='large'>
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <Title level={2}>Branch</Title>
              <BranchOption onChange={this.updateSelected} isLoading={this.updateLoading} />
            </Col>
          </Row>
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <Title level={3}>Department</Title>
              <DeptOption ref={this.deptOptionRef} onChange={this.updateSelected} branch_id={this.state.selected.branch} />
            </Col>

            <Col span={12}>
              <Title level={3}>Groups</Title>
              <GroupOption ref={this.groupsOptionRef} onChange={this.updateSelected} branch_id={this.state.selected.branch} />
            </Col>
          </Row>
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <Title level={3}>Employee Status</Title>
              <EmpStatusOption ref={this.empStatOptionRef} onChange={this.updateSelected} />
            </Col>
            <Col span={12}>
              <Title level={3}>Employee Designation</Title>
              <Tooltip placement="top" title="Clearing employee designations from selection will select all employees accross all designations.">
                <EmpDesigOption onChange={this.updateSelected} branch_id={this.state.selected.branch} />
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={[32, 16]}>

          </Row>
          <Divider />
          <Text strong>Excludes are applied after gathering all possible employees.</Text>
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <Title level={3}>Exclude Groups</Title>
              <ExGroupOption ref={this.exGroupsOptionRef} onChange={this.updateSelected} branch_id={this.state.selected.branch} />
            </Col>
          </Row>
          <Divider />
          <EmpList
            ref={this.empListRef}
            selected_ids={this.state.selected}
            excluded_ids={this.updateExcluded}
            staging={this.state.staging}
            selected_count={this.updateSelectedCount}
            loading={this.state.branches_loading}
          />
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <SendButton
                ref={this.sendButtonRef}
                character_limit={this.state.character_limit}
                onStage={this.handleStaging}
                messageLength={this.updateSMSCharCount}
                onSend={this.handleSend}
                selected_ids={this.state.selected}
                excluded_ids={this.state.excluded_ids} />
            </Col>
            <Col span={12}>
              <TwilioAccount
                branch_id={this.state.selected.branch}
                onChange={this.updateSelected}
                onCharLimitLoad={this.updateCharacterLimit}
                selected_recepient_count={this.state.selected_count}
                message_char_count={this.state.message_char_count} />
            </Col>
          </Row>
        </Form>
      </div >
    );
  }
}

export default ShoutTool;
