import React, { Component } from 'react';

import SparklesHistory from './SparklesHistory';
import { Typography, Tabs, Divider, Card, Row, Col, Table, Checkbox, Popconfirm, Skeleton, Tooltip } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';

import cronstrue from 'cronstrue';
import parser from 'cron-parser';

import axiosInstance from '../../axiosApi';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

type Props = {};
type State = {
  loading: boolean,
  current_tab: string,
  items: any[],
  history: any[];
};

function time_tzabbrev(in_date: Date) {
  return in_date.toLocaleString(
    'en-US', { // abbreviate timezone (must do manually)
      timeZoneName: 'long' 
    }).split(
      ' '
    ).slice(3).map(
      el => el[0]
    ).join(
      ''
    ).toUpperCase();
};

function time_nextrun(in_date: Date) {
  return in_date.toLocaleString('en-US', {
    weekday: 'short', 
    month: 'short', 
    day: 'numeric', 
    hour:'2-digit', 
    hour12: true, 
    minute: '2-digit',
  }) + " " + time_tzabbrev(in_date);

};

function time_localize(in_date: Date) {
  const now = new Date(Date());
  const tz_offset = now.getTimezoneOffset() / 60;
  const next_date = in_date;

  console.log(
    "Now: " + now + "\n" +
    "Offset: " + tz_offset + "\n" + 
    "t(Next): " + next_date + "\n"
  );

  next_date.setHours( // Resets TZ
    next_date.getHours() //+ -tz_offset
  );

  console.log(
    "t(NextAdj): " + next_date + "\n"      
  );

  return next_date;

};

class Sparkles extends Component<Props, State> {
  state: State = {
    loading: true,
    current_tab: "1",
    items: [],
    history: []
  };

  componentDidMount() {
    this.load();
  }

  onSparkleUpdate = (sparkle_id: any) => {

    //This is so gross but here it goes
    let items = [...this.state.items]

    let i = -1
    for (let j = 0; j < this.state.items.length && i == -1; j++) {
      if (this.state.items[j].id == sparkle_id) {
        i = j
      }
    }

    let item = { ...items[i] }

    axiosInstance.post('/sparkles/enabled/', {
      sparkle_id: sparkle_id,
      sparkle_enabled: !item.enabled
    }).then(response => {
      item.enabled = !item.enabled
      items[i] = item
      this.setState({ items })
    }).catch(error => {
      items[i] = item
      this.setState({ items })
    })
  }

  onNotifUpdate = (sparkle_id: any, notif_id: any) => {
    //This is so gross but here it goes
    let sparkles = [...this.state.items]

    let sparkle_index = -1
    for (let j = 0; j < this.state.items.length && sparkle_index == -1; j++) {
      if (this.state.items[j].id == sparkle_id) {
        sparkle_index = j
      }
    }

    let sparkle = { ...sparkles[sparkle_index] }
    sparkles[sparkle_index] = sparkle

    let notif_index = -1
    for (let j = 0; j < this.state.items.length && notif_index == -1; j++) {
      if (this.state.items[sparkle_index].notifications[j].id == notif_id) {
        notif_index = j;
      }
    }

    let notif = { ...sparkles[sparkle_index].notifications[notif_index] }

    axiosInstance.post('/sparkles/notifications/enabled/', {
      sparkle_id: sparkle_id,
      notification_id: notif_id,
      notification_enabled: !notif.enabled,
    }).then(response => {
      notif.enabled = !notif.enabled
      sparkles[sparkle_index].notifications[notif_index] = notif
      this.setState({ items: sparkles })
    }).catch(error => {
      sparkles[sparkle_index].notifications[notif_index] = notif
      this.setState({ items: sparkles })
    })
  }

  

  load = () => {
    try { // does not apply to promises (i.e. ".then()")
      axiosInstance.get('sparkles/list/')
        .then(res => {
          try {
            const obj = res.data;            

            obj.forEach((o: any) => { 
              o.schedule = cronstrue.toString(o.run_schedule);
              o.next_date =  time_localize(
                new Date(parser.parseExpression(o.run_schedule).next().toString())
              );
            });

            this.setState({
              items: obj.reverse(),
              loading: false
            });
          } catch (e) {
            console.log(res.statusText + "; " + res.config.url + "-> " + res.data.length + "B\n" + e);
          }
        });
      
    } catch (error) {
      console.log("Error Loading Sparkles: ", JSON.stringify(error, null, 4));
    }
  };

  changeTab = (newTab: string) => {
    this.setState({ current_tab: newTab });
  };

  render() {
    const options = this.state.items.map((d: any, index: number) => {
      return (
        <div key={index}>
          <Card title={d.name} >
            
            <strong>{
            d.schedule
            }</strong>
            <br/><strong>Next Run: {time_nextrun(d.next_date)}
              </strong>
            
            <p>{d.description}</p>
            <Popconfirm placement="right" title="Are you sure?" onConfirm={(e) => this.onSparkleUpdate(d.id)} onCancel={(e) => { }} okText="Confirm Change" cancelText="I changed my mind.">
              <Checkbox checked={d.enabled} onChange={(e) => { }}>Enable Sparkle</Checkbox>
            </Popconfirm>
            <Divider />
            <Row>
              <Col span={12}>
                <Title level={4}>Rules</Title>
                {d.rules.map((r: any, index: any) => {
                  return (
                    <div key={index}>
                      <strong>{r.name}</strong>
                      <p>{r.notes}</p>
                    </div>
                  );
                })}
              </Col>
              <Col span={12}>
                <Title level={4}>Notifications</Title>
                {d.notifications.map((n: any, index: number) => {
                  return (
                    <div key={index}>
                      <strong>{n.name}</strong>
                      <p>{n.notes}</p>
                      <Popconfirm placement="right" title="Are you sure?" onConfirm={(e) => this.onNotifUpdate(d.id, n.id)} onCancel={(e) => { }} okText="Confirm Change" cancelText="I changed my mind.">
                        <Checkbox checked={n.enabled} onChange={(e) => { }}>Enable Notification</Checkbox>
                      </Popconfirm>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Card>
          <Divider />
        </div>
      );
    });

    const dataSource = this.state.history;

    const columns = [
      {
        title: 'Time Stamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
      },
      {
        title: 'Recipient',
        dataIndex: 'recipient',
        key: 'recipient',
      },
      {
        title: 'Message',
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: 'Sending Result',
        dataIndex: 'response',
        key: 'response',
      },
      {
        title: 'Branch',
        dataIndex: ['sparkle_run', 'sparkle', 'branch_owner', 'name'],
        key: 'branch_owner',
      }
    ];

    return (
      <div>
        {
          this.state.loading ?
            <Skeleton active /> :
            <div>
              <div>
                <Title>Sparkles</Title>
                <Text strong>Sparkles allows you to create visit reminders for employees, get visit late alerts, and much more!</Text>
                <Divider />
              </div>
              <Tabs defaultActiveKey="1" activeKey={this.state.current_tab} onTabClick={this.changeTab}>
                <TabPane tab="Sparkle Settings" key="1">
                  {options}
                </TabPane>

                <TabPane tab={
                  <span>
                    <HistoryOutlined />
                    History
                  </span>
                } key="2">
                  <SparklesHistory />

                </TabPane>
              </Tabs>
            </div>
        }
      </div>

    );
  }
}

export default Sparkles;
