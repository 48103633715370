import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

//this does a few things
// sets up each request so it's going to be handled with normal calls without overhead
// renews the token if it expires on a failed call

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 90000,
  headers: {
    'Authorization': localStorage.getItem('access_token') ? "JWT " + localStorage.getItem('access_token') : null,
    'Content-Type': 'application/json',
    'accept': 'application/json'
  }
});

const refreshTokenDuringRequest = async (refresh_token: string | null, originalRequest: AxiosRequestConfig) => {
  try {
    const response: AxiosResponse<any> = await refreshToken(refresh_token);

    axiosInstance.defaults.headers['Authorization'] = "JWT " + response?.data.access;
    originalRequest.headers['Authorization'] = "JWT " + response.data.access;

    return axiosInstance(originalRequest);
  } catch (err) {
    console.log(err);
  }
};

export const refreshToken = async (refresh_token: string | null) => {
  if (!refresh_token) {
    return Promise.reject('No refresh token provided');
  }

  try {
    const response: AxiosResponse<any> = await axiosInstance.post('/api/token/refresh/', { refresh: refresh_token });

    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);

    return response;
  } catch (err) {
    console.log(err);
    //@ts-ignore
    return Promise.reject({ err });
  }
};

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    // test for token presence, no point in sending a request if token isn't present
    if (localStorage.getItem('refresh_token')
      && error.response.status === 401
      && error.response.data.detail !== "No active account found with the given credentials") {
      const refresh_token = localStorage.getItem('refresh_token');

      return refreshTokenDuringRequest(refresh_token, originalRequest);
    }
    // specific error handling done elsewhere
    return Promise.reject({ ...error });
  }
);

export default axiosInstance;
