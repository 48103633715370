import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import sspReducer from '../features/ServerSidePaginator/sspSlice';
import sspSparklesReducer from '../features/ServerSidePaginator/sspSliceSparkles';
import isLoadingReducer from './reducers/isLoadingReducer';
import authReducer from './reducers/authReducer';
// a

export const store = configureStore({ // <-- func def that takes an object
  reducer: {
    sspSparkles: sspSparklesReducer,
    ssp: sspReducer,
    isLoading: isLoadingReducer,
    isAuth: authReducer,
  },
});

export type AppDispatch = typeof store.dispatch; // <-- HERE'S THE DISPATCH
export type RootState = ReturnType<typeof store.getState>; // <-- TypeScript type inference
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
