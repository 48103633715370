import React, { Component } from 'react';
import axiosInstance from '../axiosApi';
import { Tooltip, Radio, Spin } from 'antd';
import { store } from '../redux/store'

type Props = {
  onChange: (key: string, value: any) => void,
  isLoading: (isLoading: boolean) => void,
};
type State = {
  selected: string,
  items: any[],
  is_loading: boolean,
};

class BranchOption extends Component<Props, State> {

  state: State = {
    selected: '0',
    items: [],
    is_loading: true,
  };

  dispatchLoading() {               //this function passes true to a store value for EmpStatus when the branche is changed
    //it has to be done like this because branch change doesn't trigger EmpStatus Component (it only renders once)
    store.dispatch({
      type:"ISLOADING_EmpStatus",
      payload:true
    })


  }


  componentDidMount() {
    this.load();
  }

  handleChange = (e: any) => {
    this.setState({
      selected: e.target.value,
    });

    this.dispatchLoading()
    // This is going to pass the selected.branch: e back up to the parent components state
    this.props.onChange("branch", e.target.value);
  };

  load = () => {
    this.props.isLoading(this.state.is_loading);

    try {
      axiosInstance.post('shout/branches/')
        .then(res => {
          const obj = res.data.branches;
          this.setState({
            selected: obj[0].branch_id,
            items: obj,
            is_loading: false
          }, () => {
            this.props.onChange("branch", obj[0].branch_id);
            this.props.isLoading(this.state.is_loading);



          });
        });
    } catch (error) {
      console.log("Error Loading Branches: ", JSON.stringify(error, null, 4));
    }
  };

  render() {
    const options = this.state.items.map(d => <Radio.Button value={d.branch_id} key={d.branch_id}>{d.branch_name}</Radio.Button>);
    return (
      <div>
        {this.state.is_loading ? (
          <div className="branchLoading">
            <Spin />
          </div>) : (
          <Tooltip placement="top" title="Selecting the branch will update available departments and groups.">
            <Radio.Group
              onChange={this.handleChange}
              defaultValue={this.state.selected}
              value={this.state.selected}
              style={{ width: '100%' }}
            >
              {options}
            </Radio.Group>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default BranchOption;
