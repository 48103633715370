import React, { Component } from 'react';
import axiosInstance from '../axiosApi';
import { Statistic, Row, Col, Skeleton, Alert } from 'antd';

type Props = {
  branch_id: string,
  message_char_count: number,
  selected_recepient_count: number,
  onCharLimitLoad: (segments: number) => void,
  onChange: (key: string, value: any) => void,
};
type State = {
  currency: number,
  balance: number,
  phone_number: number,
  us?: any,
  ca?: any,
  au?: any,
  loading: boolean,
  testing: boolean,
  testing_number: number;
  branch:string
};

class TwilioAccount extends Component<Props, State> {
  state: State = {
    branch:'',
    currency: 0,
    balance: 0,
    phone_number: 0,
    loading: true,
    testing: false,
    testing_number: +1112223333
  };

  componentDidMount() {
    this.load();
    
  }

  load = () => {
    if (this.props.branch_id !== undefined) {
      this.setState({
        branch:this.props.branch_id
      })
      try {
        let payload = {
          "branch_id": this.props.branch_id
        };

        axiosInstance.post('shout/twilio_account/', payload)
          .then(res => {
            const obj = res.data.twilio_account;
            this.setState({
              loading: false,
              currency: obj.currency,
              balance: obj.balance,
              phone_number: obj.phone_number,
              us: obj.us,
              ca: obj.ca,
              au: obj.au,
              testing: obj.is_twilio_testing,
              testing_number: obj.testing_number
            });
            this.props.onCharLimitLoad(this.calcMessageLimit(obj.message_segment_limit));
          });
      } catch (error) {
        console.log("Error Loading Twilio Account Info", JSON.stringify(error, null, 4));
      }
    }
  };

  // determines the weird header size and new character limit based on user inputted characters
  calcMessageLimit = (segments: number) => {
    // each header over 1 message is 7 characters
    // each segments is 160 character        
    if (segments > 1)
      return 153 * segments;
    return 160;
  };

  calcUsedChars = () => {
    const char_count = this.props.message_char_count;
    if (char_count <= 160) {
      return 1;
    }

    const segs = Math.ceil(char_count / 160);
    const char_count_with_header = char_count + (7 * segs);
    return Math.ceil(char_count_with_header / 160);
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.branch_id !== prevProps.branch_id) {
      this.load();
     
    }
  }

  render() {
    let statistics
    if(this.state.branch=='1000')
    statistics =  <Col span={6}> <Statistic title={"Balance (" + this.state.currency + ")"} value={"$" + this.state.balance} precision={2} /></Col>

    return (
      <div>
        {this.state.loading ?
          <Skeleton active /> :
          <div>
            {this.state.testing ? <Row justify="start" gutter={[32, 16]}>
              <Alert message={"Shout is currently in test mode and will only go to " + this.state.testing_number} type="warning" />
            </Row> : null}
            <Row justify="start" gutter={[32, 16]}>
              <Col span={6}>
                <Statistic title="From Number" value={this.state.phone_number} />
              </Col>
              
              <Col span={6}>
                <Statistic title="US / CA Cost" value={"$" + this.state.us + "/segment"} />
              </Col>

              {statistics}

            </Row>
            <Row justify="start" gutter={[32, 16]}>
              <Col span={6}>
                <Statistic title="Selected Recipients" value={this.props.selected_recepient_count} />
              </Col>
              <Col span={6}>
                <Statistic title="Message Segments" value={this.calcUsedChars()} />
              </Col>
              <Col span={6}>
                <Statistic title="Estimated Cost" value={"$" + Math.ceil(this.props.message_char_count / 160) * this.state.us * this.props.selected_recepient_count} precision={4} />
              </Col>
            </Row>
            <Row justify="start">
            </Row>
          </div>
        }
      </div>
    );
  }
}

export default TwilioAccount;
