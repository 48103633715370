import React, { Component } from 'react';

import axiosInstance from '../axiosApi';

import { Alert, Form, Input, Button, PageHeader, Card } from 'antd';

import { LockOutlined } from '@ant-design/icons';

const formItemLayout = {};
const tailFormItemLayout = {};

type Props = {};
type State = {
  show_alert: boolean,
  alert_message?: string,
  alert_type: "error" | "info" | "success" | "warning",
  is_loading: boolean,
};

class Account extends Component<Props, State> {
  state: State = {
    show_alert: false,
    alert_type: "error",
    is_loading: false,
  };

  onFinish = (values: any) => {
    console.log('Success:', values);

    this.setState({
      is_loading: true,
      show_alert: false,
    });
    axiosInstance.post('/api/user/password/change/', {
      current_password: values.curr_pw,
      new_password: values.new_pw,
      new_password_confirm: values.new_pw_confirm
    }).then(response => {
      this.setState({
        is_loading: false,
        show_alert: true,
        alert_message: "Password updated successfully.",
        alert_type: "info"
      });
    }).catch(error => {
      this.setState({
        is_loading: false,
        show_alert: true,
        alert_message: "Error changing password: " + error.response.data.error,
        alert_type: "error"
      });
    });
  };

  onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    return (
      <div>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Account Settings"
          subTitle="View and change your account settings"
        >
          <Card title="Change Password">
            <p>Passwords must be at least 16 characters long. A combination of 5 words or a phrase is a highly memorable way to achieve this.</p>
            <Form
              {...formItemLayout}
              name="changePassword"
              onFinish={this.onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="curr_pw"
                rules={[
                  {
                    required: true,
                    message: 'Please input your current password.',
                  }
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Current Password"
                  prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item
                name="new_pw"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value.length >= 16) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Your password must be at least 16 characters. Try using a personally significant phrase.');
                    },
                  })
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Password"
                  prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item
                name="new_pw_confirm"
                dependencies={['new_pw']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('new_pw') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  prefix={<LockOutlined />} />
              </Form.Item>
              {this.state.show_alert ? (
                <Form.Item>
                  <Alert message={this.state.alert_message} type={this.state.alert_type} showIcon closable />
                </Form.Item>
              ) : (null)}

              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={this.state.is_loading} >
                  Change Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </PageHeader>
      </div>
    );
  }
}

export default Account;
