import { bindActionCreators, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';


function time_tzabbrev(in_date: Date) {
    return in_date.toLocaleString(
      'en-US', { // abbreviate timezone (must do manually)
        timeZoneName: 'long' 
      }).split(
        ' '
      ).slice(3).map(
        el => el[0]
      ).join(
        ''
      ).toUpperCase();
  };
  
function time_nextrun(in_date: Date) {
    return in_date.toLocaleString('en-US', {
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric', 
      hour:'2-digit', 
      hour12: true, 
      minute: '2-digit',
    }) + " " + time_tzabbrev(in_date);
  
  };
  
function time_localize(in_date: Date) {
    const now = new Date(Date());
    const tz_offset = now.getTimezoneOffset() / 60;
    const next_date = in_date;
  
    console.log(
      "Now: " + now + "\n" +
      "Offset: " + tz_offset + "\n" + 
      "t(Next): " + next_date + "\n"
    );
  
    next_date.setHours( // Resets TZ
      next_date.getHours() //+ -tz_offset
    );
  
    console.log(
      "t(NextAdj): " + next_date + "\n"      
    );
  
    return next_date;
  
  };
export interface PageState {
    indexCurrent: number;
    urlCurrent: string;
    urlNext: string;
    urlPrev: string;
    pageSize: number;
    pageGoto: number;
    itemCount: number;
    items: Array<object>;
    status: 'idle' | 'loading' | 'failed';
}
export interface drfPaginationAPI {
    count: number;
    next: string;
    previous: string;
    results: Array<Object>;
    itemsPerPage: number; // TODO get this from backend API
}

const initialState: PageState = {
    indexCurrent: 1,
    urlCurrent: "",
    urlNext: "",
    urlPrev: "",
    pageSize: 50,
    pageGoto: 0,
    itemCount: 0,
    items: [],
    status: 'idle',
};
export const sparklesPageSlice = createSlice({
    name: 'sparklesSSP',
    initialState,
    reducers: {
        sparklesPopulateItems: (state, action: PayloadAction<drfPaginationAPI>) => {
            const obj = action.payload.results;
            console.log(obj);

           obj.forEach((v: any, k: number, a: object[]) => {
                if ('timestamp' in v) {
                    v['timestamp'] = time_nextrun( // MISNOMER, just formatting...
                        time_localize(new Date(v['timestamp']))).toString(); //Date.parse(v.timestamp);

                }
                
            });

            state.items = obj.reverse().map((item: any, index: number) => (                
                {...item, key: index }
            ));

            

            state.urlNext = action.payload.next ? action.payload.next : "";
            state.urlPrev = action.payload.previous ? action.payload.previous : "";
            state.itemCount = action.payload.count ? action.payload.count : 0;
            state.pageSize = action.payload.itemsPerPage || 50;
            console.log("ITEM COUNT " + state.itemCount);
        },
    },
});

export const { sparklesPopulateItems } = sparklesPageSlice.actions;

export default sparklesPageSlice.reducer;
