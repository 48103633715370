import React, { Component } from 'react';
import axiosInstance from '../axiosApi';

import { Button, Col, Tooltip, Typography, Input, Checkbox, Popconfirm, Alert } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { TextArea } = Input;

type Props = {
  character_limit: number,
  onStage: (b: boolean) => void,
  messageLength: (length: number) => void,
  onSend: (url: string, message_body: string) => void,
  selected_ids: any,
  excluded_ids: any,
};
type State = {
  loading: boolean,
  disabled: boolean,
  staging: boolean,
  message: string,
  send_success: any,
  show_alert?: any,
  alert_message?: string;
};

class SendButton extends Component<Props, State> {
  state: State = {
    loading: false,
    disabled: true,
    staging: false,
    message: "",
    send_success: {},
  };

  private textArea: any;

  clearFields() {
    console.log("clearing!");
    this.textArea?.setValue("");
    this.setState({
      message: ""
    });
  }

  sendList = () => {
    // TODO: Create a log entry here for the current filter settings
    this.setState({
      loading: true,
      show_alert: false
    });

    try {
      const { branch, dept, groups, ex_groups, empStatuses, empDesig } = this.props.selected_ids;
      const { excluded_ids } = this.props;
      const { message } = this.state;
      const payload = {
        "branch_id": branch,
        "department_id": dept,
        "ac_group_ids": groups,
        "ac_ex_group_ids": ex_groups,
        "emp_statuses": empStatuses,
        "emp_desigs": empDesig,
        "exclude_users": excluded_ids,
        "message": message,
      };

      axiosInstance.post('shout/send/', payload)
        .then(res => {
          const obj = res.data.emp_list;
          this.handleSendResult(obj);
        }).catch(error => {
          this.setState({
            show_alert: true,
            alert_message: "There's been an error sending your message. Please copy the following text into a support ticket so Rubi Works can spring into action: " + error.response.data.message,
            loading: false,
            staging: false,
            disabled: false,
          });
        });
    } catch (error) {
      console.log("Error Sending Messages: ", JSON.stringify(error, null, 4));
    }
  };

  handleSendResult = (e: any) => {
    console.log(e);
    // pass the success object up a level
    this.setState({ loading: false, staging: false, disabled: false });
    this.props.onSend(e.url, e.message_body);
  };

  handleStaging = () => {
    if (this.state.staging) {
      this.setState({
        staging: false
      });
      // kick this over to a handler function so we can pass fake data to it for testing
      this.props.onStage(false);
    } else {
      this.setState({ staging: true });
      this.props.onStage(true);
    }
  };

  handleMessageChange = (e: any) => {
    this.setState({ message: e.target.value });
    this.props.messageLength(e.target.value.length);
  };

  fakeIt = () => {
    const obj = {
      "emp_list": {
        "notification_sid": "NTd89f75f00e29ec3abf8da4a8ca2c9365",
        "url": "https://www.twilio.com/console/notify/services/ISa6c21371223d59f3885c66e3494d4f4f/logs/NTd89f75f00e29ec3abf8da4a8ca2c9365",
        "phone_list": ["+12024138320", "+17574704204"],
        "message_body": "Test message"
      }
    };
    this.handleSendResult(obj.emp_list);
  };

  render() {
    return (
      <div>
        <Col span={24}>
          <Title level={4}>Compose Message</Title>
          <TextArea
            maxLength={this.props.character_limit}
            onChange={this.handleMessageChange}
            autoSize={true}
            allowClear={true}
            ref={textArea => this.textArea = textArea}
          />
        </Col>
        <Col span={24}>
          <Checkbox onChange={this.handleStaging} checked={this.state.staging}>By checking this box you are agreeing to sending the message above to the recepient list generated. Rubi Works LLC is not reponsible for the content or potential costs caused by user error or negligence.</Checkbox>
        </Col>
        <Col span={24}>
          <Tooltip placement="bottom" title="Click here to send message to recepient list!"  >
            <Popconfirm placement="right" title="Are you sure you want to send?" onConfirm={this.sendList} okText="Send it!" cancelText="I changed my mind.">
              <Button
                className="generateshoutlists"
                type="primary"
                icon={<SendOutlined />}
                loading={this.state.loading}
                disabled={!this.state.staging}
              >
                Send SMS Notifications
              </Button>
            </Popconfirm>
          </Tooltip>
          {this.state.show_alert ? (
            <Alert message={this.state.alert_message} type="error" showIcon closable />
          ) : (null)}
        </Col>
      </div>
    );
  }
}
export default SendButton;
