import React, { Component } from 'react';
import axiosInstance from '../axiosApi';
import { Tooltip } from 'antd';
import { Select } from 'antd';
import { store } from '../redux/store'

const { Option } = Select;

type Props = {
  onChange: (key: string, value: any) => void,
};

type State = {
  selected: any[],
  items: any[],
  loading: boolean,
};

class EmpStatusOption extends Component<Props, State> {
  state: State = {
    selected: [],
    items: [],
    loading: true
  };

  clearFields() {
    console.log("clearing!");
    this.setState({
      selected: [],
    });
  }

  componentDidMount() {
    this.load();
  }

  handleChange = (e: any) => {
    this.setState({
      selected: e,
    });

    // This is going to pass the selected.valu: e back up to the parent components state
    this.props.onChange("empStatuses", e);
  };

  load = () => {
    try {
      //push a blank value back up so people know to clear ish
      this.props.onChange("empStatuses", []);

      this.setState({
        loading: true,
        selected: []
      });

      store.dispatch({                            // sending value true when component is called, so that the button in EmpList gets disabled
        type: "ISLOADING_EmpStatus",
        payload: true
      });

      axiosInstance.post('shout/employeestatuses/')
        .then(res => {
          const obj: any[] = res.data.empstatuses;

          var presets: any[] = []

          obj.forEach(curStatus => {
            if (curStatus.employee_status_name == 'active') {
              presets.push(curStatus.employee_status_name)
            }
          });

          this.setState({
            items: obj,
            loading: false
          });

          this.handleChange(presets);
          //store.dispatch is in Group component, cuz this only gets called once, so it doesn't do anything on branch change
        });
    } catch (error) {
      console.log("Error Loading Employee Statuses: ", JSON.stringify(error, null, 4));
    }
  };

  render() {
    const options = this.state.items.map((d: any) => <Option value={d.employee_status_name} key={d.employee_status_name}>{d.employee_status_name}</Option>);
    return (
      <div>
        <Tooltip placement="top" title="Clearing employee statuses from selection will select all employees accross all statuses.">
          <span>
            <Select
              mode="multiple"
              placeholder="Select Employee Statuses"
              onChange={this.handleChange}
              filterOption={(input, option) => option?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              value={this.state.selected}
              loading={this.state.loading}
              disabled={this.state.loading}
              style={{ width: '100%' }}
              allowClear
            >
              {options}
            </Select>
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default EmpStatusOption;
