import React, { Component, createRef } from 'react';

import ShoutTool from './ShoutTool';
import ShoutHistory from './ShoutHistory';
import { Typography, Tabs, Divider } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { RefObject } from 'react';

const { TabPane } = Tabs;
const { Title } = Typography;
const { Text } = Typography;

type Props = {};
type State = {
  selected: {
    branch: number,
    dept: any[],
    groups: any[],
    ex_groups: any[],
    empStatuses: any[],
    empDesig?: any,
  },
  selected_count: number,
  excluded_ids: any[],
  loading: {
    branches: boolean,
    departments: boolean,
    groups: boolean,
    ex_groups: boolean
  },
  message_char_count: number,
  staging: boolean,
  send_success: {
    url: string,
    message_body: string,
    new_entry: boolean,
  },
  current_tab: string,
};


class Shout extends Component<Props, State> {
  state: State = {
    selected: {
      branch: 0,
      dept: [],
      groups: [],
      ex_groups: [],
      empStatuses: [],
      empDesig: undefined,
    },
    selected_count: 0,
    excluded_ids: [],
    loading: {
      branches: true,
      departments: true,
      groups: true,
      ex_groups: true,
    },
    message_char_count: 0,
    staging: false,
    send_success: {
      url: "",
      message_body: "",
      new_entry: false,
    },
    current_tab: "1",
  }

  private ShoutToolRef: RefObject<ShoutTool> = createRef();

  updateSelected = (k: string, v: any) => {
    this.setState((prevState: State) => ({
      ...prevState,
      selected: {
        ...prevState.selected,
        [k]: v
      }
    }))
  }

  updateExcluded = (e: any[]) => {
    console.log("current ex list", this.state.excluded_ids);
    console.log("new ex list", e);
    this.setState({ excluded_ids: e })
  }

  updateSMSCharCount = (e: number) => {
    this.setState({
      message_char_count: e
    })
  }

  updateSelectedCount = (e: number) => {
    this.setState({ selected_count: e })
  }

  changeTab = (newTab: string) => {
    this.setState({ current_tab: newTab })
  }

  sendSuccess = (url: any, message: any) => {
    this.setState({
      send_success: {
        url,
        message_body: message,
        new_entry: true,
      }
    }, () => {
      this.changeTab("3");
      this.ShoutToolRef.current?.clearFields();
    });
  }

  render() {
    return (
      <div>
        <div>
          <Title>Shout</Title>
          <Text strong>Welcome! Shout allows you to send targeted and customized SMS notifications to Alayacare employees, clients, and contacts.</Text>
          <Divider />
        </div>
        <Tabs defaultActiveKey="1" activeKey={this.state.current_tab} onTabClick={this.changeTab}>
          <TabPane tab="Employee Notifications" key="1">
            <ShoutTool handleSend={this.sendSuccess} ref={this.ShoutToolRef} />
          </TabPane>

          <TabPane tab="Client Notifications" key="2" disabled>
            Client notifications are currently under development.

            ...wait. How did you get in here?! Guards!!
          </TabPane>

          <TabPane tab={
            <span>
              <HistoryOutlined />
              History
            </span>
          } key="3">
            <ShoutHistory />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Shout;
