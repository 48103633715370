const initialState = {
    isLoadingDept: true,
    isLoadingEmpDesig: true,
    // isLoadingEmpStatus:true,
    isLoadingGroup: true,
    isLoadingExGroup: true
};

const isLoadingReducer = (state = initialState, action: any) => {   //Initial state is used if action is undefined, otherwise it will break
    switch (action.type) {
        case "ISLOADING_Dept":

            return {
                ...state,
                isLoadingDept: action.payload
            };
        case "ISLOADING_EmpDesig":

            return {
                ...state,
                isLoadingEmpDesig: action.payload
            };
        case "ISLOADING_EmpStatus":

            return {
                ...state,
                isLoadingEmpStatus: action.payload
            };
        case "ISLOADING_Group":

            return {
                ...state,
                isLoadingGroup: action.payload
            };
        case "ISLOADING_Ex_Group":

            return {
                ...state,
                isLoadingExGroup: action.payload
            };
        default:
            return state;
    }
};

export default isLoadingReducer
