import React, { Component } from 'react';
import axiosInstance from '../axiosApi';
import { Tooltip } from 'antd';
import { Select } from 'antd';
import { store } from "../redux/store"

const { Option } = Select;

type Props = {
  branch_id: number,
  onChange: (key: string, value: any) => void,
};
type State = {
  selected?: string,
  items: any[],
  loading: boolean,
};

class EmpDesigOption extends Component<Props, State> {
  state: State = {
    items: [],
    loading: true,
  };

  clearFields() {
    console.log("clearing!");
    this.setState({
      selected: undefined
    });
  }

  componentDidMount() {
    this.load();
  }

  handleChange = (e: any) => {
    this.setState({
      selected: e,
    });

    // This is going to pass the {selected_branch: e} back up to the parent component
    this.props.onChange("empDesig", e);
  };

  load = () => {
    if (this.props.branch_id !== undefined) {
      try {
        // clear the parent just in case it's got a value
        this.props.onChange("empDesig", undefined);

        let payload = {
          "branch_id": this.props.branch_id
        };

        this.setState({
          loading: true,
          selected: undefined
        });
        store.dispatch({                           // sending value true when component is called, so that the button in EmpList gets disabled
          type: "ISLOADING_EmpDesig",
          payload:true
        });

        axiosInstance.post('shout/employeedesigs/', payload)
          .then(res => {
            const obj = res.data.empdesigs;
            this.setState({
              items: obj,
              loading: false
            });
            store.dispatch({                                  // this is sending false to store component, and enables the button along with other components
              type: "ISLOADING_EmpDesig",
              payload:this.state.loading
            });

          });
      } catch (error) {
        console.log("Error Loading Employee Designations: ", JSON.stringify(error));
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.branch_id !== prevProps.branch_id) {
      this.load();
    }
  }

  render() {
    const options = this.state.items.map((d: any) => <Option value={d.designation_name} key={d.designation_name}>{d.designation_name}</Option>);
    return (
      <div>
        <Tooltip placement="top" title="Clearing employee designations from selection will select all employees across all designations.">
          <span>
            <Select
              showSearch
              placeholder="Select an Employee Designation"
              onChange={this.handleChange}
              filterOption={(input, option) => option?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp="children"
              value={this.state.selected}
              loading={this.state.loading}
              disabled={this.state.loading}
              style={{ width: '100%' }}
              allowClear
            >
              {options}
            </Select>
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default EmpDesigOption;
